@import "meteojob-theme/lib/skins/_default/sass/misc/functions";
@import "_variables";
@import "meteojob-theme/lib/skins/_default/sass/misc/mixins";

$homepage-transition: background-color 250ms,border-color 250ms,color 250ms, box-shadow 250ms;

body.front {

  h1, h2, h4 {
    font-weight: 700;
  }

  h2 {
    margin-bottom: prem($uikit-spacing-4);
    font-size: prem(30px);
    line-height: prem(38px);

    @media(min-width: $screen-lg-min) {
      margin-bottom: prem($uikit-spacing-6);
      font-size: prem(46px);
      line-height: prem(60px);
    }
  }

  h3 {
    margin-bottom: prem($uikit-spacing-8);
    font-size: prem(16px);
    line-height: prem(22px);

    @media(min-width: $screen-lg-min) {
      margin-bottom: prem($uikit-spacing-16);
      font-size: prem(22px);
      line-height: prem(30px);
    }
  }

  h4 {
    font-size: prem(22px);
    line-height: prem(30px);
  }

  p {
    font-size: prem(16px);
    line-height: prem(22px);

    @media(min-width: $screen-lg-min) {
      font-size: prem(18px);
      line-height: prem(24px);
    }
  }

  .hp-container {
    padding: prem($uikit-spacing-12) prem($uikit-spacing-4);

    @media(min-width: $screen-lg-min) {
      padding: prem($uikit-spacing-20) prem($uikit-spacing-4);
      
      &#hero {
        padding: prem($uikit-spacing-44) 0;
      }
    }
  }

  #modals-wrapper,
  .node-home-page {
    .btn {
      padding: prem($uikit-spacing-3) prem($uikit-spacing-6);
      font-weight: 500;
    }  
  }

  .node-home-page {
    .top-level-container {
      margin: 0 auto;
      @media(min-width: $screen-lg-min) {
        padding: 0 $uikit-spacing-16;
      }
    }
    #hero {
      .top-level-container {
        position: relative;

        @media(min-width: $screen-lg-min) {
          padding: 0 $uikit-spacing-80 0 $uikit-spacing-16;
        } 
      }
    }
    #special-week,
    #why,
    #how,
    #clusters,
    #cities,
    #companies,
    #news {
      .top-level-container {
        @media(min-width: $screen-lg-min) {
          padding: 0 $uikit-spacing-44;
        }
      }
    }
  }

  #special-week,
  #why,
  #how,
  #companies,
  #news {
    h2, h3 {
      text-align: center;
    }
  }
  
  #hero {
    margin-top: $header-top-height;
    background-color: $uikit-blue-sky-100;
  
    .hero-image {
      position: absolute;
      top: $header-top-height;
      right: 0;

      img {
        width: initial;
        height: initial;
      }
    }

    h1 {
      margin-bottom: prem($uikit-spacing-4);
      margin-right: prem(80px); // image
      font-size: prem(38px);
      line-height: prem(50px);

      @media(min-width: $screen-lg-min) {
        margin-right: 0;
      }

      span:first-child {
        color: $brand-primary-alt;
        @media(min-width: $screen-lg-min) {
          display: block;
        }
      }
    }

    h2 {
      margin-bottom: prem($uikit-spacing-8);
      color: $uikit-blue-night-400;
      font-size: prem(16px);
      line-height: prem(22px);
    }

    @media(min-width: $screen-lg-min) {
      h1 {
        margin-bottom: prem($uikit-spacing-8);
        font-size: prem(56px);
        line-height: prem(73px);
      }
      h2 {
        font-size: prem(22px);
        line-height: prem(30px);
      }
    }

    .search-form {
      position: relative;

      form {
        display: flex;
        flex-direction: column;
        justify-items: stretch;
      }
      .form-mask {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .form-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: prem($uikit-spacing-4);
        padding: prem($uikit-spacing-4) prem($uikit-spacing-8);
        background: $input-bg;
        border-radius: prem(50px);
        box-shadow: 0px 0px 2px 0px #4F5E711F,
                    0px 2px 4px 0px #4F5E711C,
                    0px 4px 8px 0px #4F5E711A;

        input {
          width: 100%;
          font-size: prem($uikit-body-font-size-large);
          font-weight: 500;
          margin-left: prem($uikit-spacing-4);
          padding: 0;
          border: 0 none;
        }
        .mat-icon {
          font-size: prem(30px);
          font-weight: 500;
          text-align: center;
        }
      }

      @media (min-width: $screen-lg-min) {
        form {
          flex-direction: row;
          padding: prem($uikit-spacing-4);
          background: $input-bg;
          border-radius: prem(50px);
          box-shadow: 0px 0px 2px 0px #4F5E711F,
                      0px 2px 4px 0px #4F5E711C,
                      0px 4px 8px 0px #4F5E711A;


          .form-group {
            flex-grow: 1;
            margin-bottom: 0;
            padding: prem($uikit-spacing-3) prem($uikit-spacing-6);
            box-shadow: none;
          }
          .field-separator {
            border-left: 1px solid $uikit-blue-night-200;
          }
        }
      }  
    }
  }

  #clusters,
  #cities {
    ul {
      margin: 0;
      padding: 0;

      & > li {
        display: inline-block;
        list-style: none;
      }
    }

    a[data-toggle=collapse] {
      display: inline-flex;
      margin-top: prem($uikit-spacing-4);
      font-size: prem(16px);
      font-weight: 600;
      line-height: prem(22px);
    }

    .display-tag {
      margin-right: prem($uikit-spacing-3);
      margin-bottom: prem($uikit-spacing-3);
      padding: prem($uikit-spacing-2);
      border: 1px solid $uikit-blue-night-200;
      background-color: $uikit-neutral-white;
      font-size: prem($uikit-body-font-size-small);
      font-weight: 500;
      line-height: prem(18px);
      border-radius: prem(4px);
      box-shadow: none !important;
      transition: $homepage-transition;

      &:hover {
        color: $uikit-neutral-white;
        background-color: $text-color;
        border-color: 1px solid $text-color;
      }
    }

    h3 {
      margin-bottom: prem($uikit-spacing-6);
      @media (min-width: $screen-lg-min) {
        margin-bottom: prem($uikit-spacing-8);
      }
    }

    .main-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: prem($uikit-spacing-4);
      margin-bottom: prem($uikit-spacing-6);

      @media (min-width: $screen-lg-min) {
        flex-direction: row;
        gap: prem($uikit-spacing-8);
        margin-bottom: prem($uikit-spacing-8);
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      gap: prem($uikit-spacing-1);
      padding: prem($uikit-spacing-4);
      color: $text-color;
      border: 1px solid $uikit-blue-night-200;
      border-radius: prem(10px);
      transition: $homepage-transition;

      &:hover {
        background-color: $brand-primary-alt;
        color: $uikit-neutral-white;
        text-decoration: none;

        .item-icon,
        .item-text {
          color: $uikit-neutral-white;
        }
      }

      @media (min-width: $screen-lg-min) {
        gap: prem($uikit-spacing-8);
        padding: prem($uikit-spacing-6);
      }

      .item-icon {
        font-size: prem(40px);
        font-weight: 600;
        color: $brand-primary-alt;
      }
      .item-text-container {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;

        .item-text {
          flex-grow: 1;
        }
      }
    }
  }

  #clusters .item-text,
  #cities .item-name {
    font-size: prem(18px);
    line-height: prem(24px);
    font-weight: 600;
    @media (min-width: $screen-lg-min) {
      font-size: prem(22px);
      line-height: prem(30px);
    }
  }

  #clusters {
    @media(min-width: $screen-lg-min) {
      padding: prem($uikit-spacing-20) prem($uikit-spacing-4) prem($uikit-spacing-10);
    }

    .item {
      flex-direction: row;
      align-items: center;
      gap: prem($uikit-spacing-8);
      
      @media (min-width: $screen-lg-min) {
        flex-direction: column;
        align-items: normal;
      }
    }
  }
  #cities {
    @media(min-width: $screen-lg-min) {
      padding: prem($uikit-spacing-10) prem($uikit-spacing-4) prem($uikit-spacing-20);
    }

    .item-text {
      color: $uikit-blue-night-500;
    }
  }

  #logos {
    padding: prem($uikit-spacing-8) 0;

    .logos-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .logo {
        flex: 1 50%;
        padding: prem($uikit-spacing-4);
        text-align: center;

        @media (min-width: $screen-sm-min) {
          flex: 1 33%;
        }

        @media (min-width: $screen-lg-min) {
          flex: 1 auto;
        }

        img {
          transition: opacity 250ms;
        }
      }
    }

    @media (min-width: $screen-lg-min) {
      padding: prem($uikit-spacing-16) 0;
    }
  }

  #why {
    background: linear-gradient(180deg, $uikit-neutral-white 0%, $uikit-blue-sky-100 67.5%);

    h4 {
      margin-bottom: prem($uikit-spacing-6);

      @media (min-width: $screen-lg-min) {
        margin-bottom: prem($uikit-spacing-8);
      }
    }

    p {
      padding: prem($uikit-spacing-6);
      background-color: $uikit-neutral-white;
      border-radius: prem(20px);
      font-weight: 500;

      @media (min-width: $screen-lg-min) {
        display: flex;
        align-items: center;
        flex-grow: 1;
        min-height: 0;
        padding: prem($uikit-spacing-8);
      }
    }

    h4, p {
      text-align: center;
    }
    
    .why-items-container {
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-lg-min) {
        flex-direction: row;
        justify-content: space-between;
        gap: prem($uikit-spacing-24);
      }  
    }
  
    .why-item {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: prem($uikit-spacing-8);
      align-self: strech;
    }
  }  

  #how {
    .how-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: prem($uikit-spacing-8);
      padding: prem($uikit-spacing-12) 0;

      &:last-child {
        padding-bottom: 0 !important;
      }

      h4 {
        display: flex;
        margin-bottom: prem($uikit-spacing-4);
        font-size: prem(30px);
        line-height: prem(38px);

        @media (min-width: $screen-lg-min) {
          margin-bottom: prem($uikit-spacing-6);
          font-size: prem(46px);
          line-height: prem(60px);
        }
      }

      img {
        max-width: prem(250px);
        align-self: center;
      }

      p {
        margin-bottom: prem($uikit-spacing-6);
        font-weight: 600;
        @media (min-width: $screen-lg-min) {
          margin-bottom: prem($uikit-spacing-8);
        }
      }

      @media (min-width: $screen-lg-min) {        
        flex-direction: row;
        gap: prem($uikit-spacing-20);
        padding: prem($uikit-spacing-20) 0;

        &:nth-child(even) {
          flex-direction: row-reverse;
        }

        img {
          max-width: prem(350px);
        }

        .how-item-text {
          flex: 1;
        }
      }
    }
  }

  #mobile-app {
    .mobile-app-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background-color: $uikit-blue-sky-100;
      border-radius: prem(20px);  

      @media (min-width: $screen-lg-min) {
        flex-direction: row;
      }
    }

    h3 {
      margin-bottom: prem($uikit-spacing-6);

      @media (min-width: $screen-lg-min) {
        margin-bottom: prem($uikit-spacing-8);
      }
    }

    .mobile-app-text {
      padding: prem($uikit-spacing-10) prem($uikit-spacing-8);
      
      img {
        width: prem(135px);
        @media (min-width: $screen-lg-min) {
          width: prem(130px);
        }
      }
      
      a + a {
        margin-left: prem($uikit-spacing-3);
      }

      @media (min-width: $screen-lg-min) {
        max-width: 50%;
        padding: prem($uikit-spacing-16) prem($uikit-spacing-14);
      }
    }

    .mobile-app-image {
      display: flex;
      justify-content: center;
      max-height: prem(200px);
      padding: 0 prem($uikit-spacing-8);
      text-align: center;
      @media (min-width: $screen-lg-min) {
        max-height: none;
        padding: 0;
      }
    }
  }

  #companies,
  #news {
    .items-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: prem($uikit-spacing-9);
      overflow-x: scroll;
      scrollbar-width: none;
      margin-bottom: prem($uikit-spacing-8);
      margin-left: prem(-$uikit-spacing-4);
      margin-right: prem(-$uikit-spacing-4);
      padding: 0 prem($uikit-spacing-4);

      @media (min-width: $screen-lg-min) {
        gap: prem($uikit-spacing-16);
        overflow-x: auto;
        margin-bottom: prem($uikit-spacing-16);
        margin-left: 0;
        margin-right: 0;
        padding: 0;
      }

      .item {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: prem(252px);
        background-color: $uikit-neutral-white;
        border: prem(1px) solid $uikit-blue-night-100;
        border-radius: prem(10px);
        transition: $homepage-transition;

        &:hover {
          border-color: $uikit-blue-night-600;

          .item-more {
            color: $brand-primary-alt;
            border-bottom: 1px solid $brand-primary-alt;
          }
        }

        .media-container {
          position: relative;
          
          .image-container {
            overflow: hidden;
            border: prem(6px) solid $uikit-neutral-white;
            border-radius: prem(10px);  
            height: prem(224px);

            img {
              object-fit: cover;
              max-width: none;
            }
          }

          .logo-container {
            position: absolute;
            bottom: prem(-20px);
            width: prem(60px);
            height: prem(60px);
            margin-left: prem($uikit-spacing-4);
            background-color: $uikit-neutral-white;
            border: prem(1px) solid $uikit-gray-200;
            border-radius: prem(4px);
  
            @media (min-width: $screen-lg-min) {
              width: prem(80px);
              height: prem(80px);
              bottom: prem(-34px);
            }  
          }
        }

        .item-tag {
          display: inline-block;
          margin-bottom: prem($uikit-spacing-3); 
          padding: prem($uikit-spacing-2);
          font-size: prem($uikit-body-font-size-extra-small);
          line-height: prem(18px);
          color: $text-color;
          background-color: $uikit-blue-sky-200;
          border-radius: prem(4px);
        }

        .text-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: flex-start;
          padding: prem($uikit-spacing-4);

          @media (min-width: $screen-lg-min) {
            padding: prem($uikit-spacing-6) prem($uikit-spacing-5);
          }

          h4 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            flex: 1;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            line-clamp: 5;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .item-more {
          display: inline-flex;
          padding-bottom: prem($uikit-spacing-1); 
          color: $text-color;
          text-decoration: none !important;
          border-bottom: 1px solid $text-color;
          transition: $homepage-transition;

          span {
            margin-left: prem($uikit-spacing-1);
          }

          &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: "";
          }
        }
      }
    }
    @media (min-width: $screen-lg-min) {
      .button-container {
        text-align: center;
      }  
    }
  }

  #companies {
    background-color: $uikit-blue-sky-100;

    .text-container {
      padding: prem($uikit-spacing-8) prem($uikit-spacing-4) prem($uikit-spacing-6) prem($uikit-spacing-4) !important;

      @media (min-width: $screen-lg-min) {
        padding: prem($uikit-spacing-12) prem($uikit-spacing-5) prem($uikit-spacing-6) prem($uikit-spacing-5) !important;
      }
    }
  }

  //== Popin
  #popin {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .cc-a-like {
      color: $text-color;
    }

    p {
      flex-grow: 1;
      margin-bottom: 0;
      font-weight: 500;
    }

    #special-week {
      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        justify-content: space-between;
        gap: prem($uikit-spacing-6);
        
        @media (min-width: $screen-lg-min) {
          flex-direction: row;
          gap: prem($uikit-spacing-8);
        }
  
        li {
          display: flex;
          flex: 1;
          
          @media (min-width: $screen-lg-min) {
            max-width: 50%;
            margin: 0 auto;
          }

          .cc-obf-link {
            display: flex;
            flex-direction: row;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            gap: prem($uikit-spacing-6);
            padding: prem($uikit-spacing-4);
            background-color: $uikit-blue-sky-100;
            border-radius: prem(20px);
    
            @media (min-width: $screen-lg-min) {
              padding: prem($uikit-spacing-8) prem($uikit-spacing-6);
            }
          }
        }
      
        .special-week-image-container {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: prem(64px);
          height: prem(64px);
          overflow: hidden;
          background: $uikit-neutral-white;
          border-radius: prem(8px);
        }
      }
    }

    #sponsoring {
      ul {
        text-align: center;
        li {
          display: inline-block;
        }  
      }
      .sponsoring-image-container {
        display: block;
        text-align: center;
      }
    }
  }
}
